import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { RichText } from 'prismic-reactjs';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Decoration1 from '../components/Decoration/Decoration-1';
import VideoYoutube from '../components/VideoYoutube';

import '../assets/styles/pages/mind-hub-page.scss';
import StartJourneyButton from '../components/StartJourneyButton';

const MindHubPage = ({ data }) => {
  const prismicData = data.prismic.allMind_hub_pages.edges[0].node;
  return (
    <Layout>
      <SEO title="Mind Hub" />
      <div className="mind-hub-page">
        <FirstSection data={data} prismicData={prismicData} />
        <SecondSection prismicData={prismicData} />
      </div>
    </Layout>
  );
};

const FirstSection = ({ data: { imageFirstSection }, prismicData: { first_section_title } }) => (
  <section className="common-first-section">
    <div className="container">
      <div className="cfs__wrapper">
        <div className="cfs__l">
          <div>
            <h1 className="c-h1 cfs__l__title">{RichText.asText(first_section_title)}</h1>
            <StartJourneyButton text={'Start your journey'} />
          </div>
        </div>
        <div className="cfs__r">
          <Decoration1 />
          <Img fluid={imageFirstSection.childImageSharp.fluid} />
        </div>
      </div>
    </div>
  </section>
);

const SecondSection = ({ prismicData: { second_section_content, video_link, video_preview } }) => (
  <section className="c-section-mt second-section">
    <div className="container">
      <div className="row">
        <div className="col-12">
          {RichText.render(second_section_content)}
          <div className="second-section__image h-100">
            <VideoYoutube url={video_link.url} imagePreview={video_preview.url} />
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default MindHubPage;

export const query = graphql`
  query {
    prismic {
      allMind_hub_pages {
        edges {
          node {
            first_section_title
            second_section_content
            video_link {
              ... on PRISMIC__ExternalLink {
                url
              }
            }
            video_preview
          }
        }
      }
    }
    imageFirstSection: file(relativePath: { eq: "photos/mind-hub/image-main.png" }) {
      childImageSharp {
        fluid(maxWidth: 816, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
